import React from 'react';
import { Outlet } from "react-router-dom";
import AdminProtectedRoute from '../../services/AdminProtectedRoute'; //:)

const AdminLayout = () => {
    return (
        <div className='flex flex-col h-screen w-screen bg-slate-900'>
            <div className='flex flex-grow overflow-auto bg-gradient-to-br from-pink-100 via-indigo-200 to-purple-200 app-panel'>
                <AdminProtectedRoute>
                    <Outlet />
                </AdminProtectedRoute>
            </div>
        </div>
    )
}

export default AdminLayout