import React from 'react'

const StudentCard = ({ student, setCurrentStudent, setManipulation, isIndex = false }) => {
    return (
        <div className='flex hover:brightness-75 relative' onClick={(() => { setManipulation(2); setCurrentStudent(student); })}>
            {
                isIndex && <div className='absolute w-full h-full bg-green-700 opacity-30'></div>
            }
            <div className='p-1 ml-2 flex justify-center'>
                <div className='m-auto'>
                    {
                        student?.id
                    }
                </div>
            </div>
            <div className='flex-grow p-4 grid grid-cols-6 gap-2 text-center'>
                <div className='flex-grow overflow-hidden break-all'>
                    {
                        student?.studentID
                    }
                </div>
                <div className='flex-grow overflow-hidden break-all'>
                    {
                        student?.nickname
                    }
                </div>
                <div className='flex-grow break-all'>
                    {
                        student?.nameTitle
                    }
                </div>
                <div className='flex-grow break-all'>
                    {
                        student?.firstName
                    }
                </div>
                <div className='flex-grow break-all'>
                    {
                        student?.lastName
                    }
                </div>
                <div className='flex-grow break-all'>
                    {
                        student?.className
                    }
                </div>
            </div>
        </div>
    )
}

export default StudentCard