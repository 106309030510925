import PIMSlogo from '../../images/PIMS Logo.png';
import Contact from '../contact/Contact';
import { UserAuth } from '../../services/authContext';
import { checkStudentAuthor } from '../../fetch/otmsFetch';
import React, { useEffect } from 'react';
import StudentAuthentication from '../otms/StudentAuthentication';
import { Navigate } from 'react-router-dom';

const Home = () => {
    const { pims, authToken, setPIMS } = UserAuth();
    const fetchData = async () => {
        const response = await checkStudentAuthor(authToken);
        setPIMS({ ...pims, studentAuthor: response.studentAuthor });
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="flex-grow overflow-auto">
            <div className="w-screen h-screen bg-gradient-to-br from-pink-100 via-indigo-200 to-purple-200 p-16">
                <div className="bg-transparent border-2 border-slate-100 w-full h-full rounded-[40px]">
                    <div className="w-full h-full relative">
                        <div className="bg-white opacity-40 rounded-[40px] absolute w-full h-full"></div>
                        <div className="relative text-center w-full h-full flex flex-col justify-center">
                            <div>
                                <img src={PIMSlogo} className="w-[200px] m-auto" />
                                <h1 className="font-bold text-indigo-700 lg:text-6xl md:text-4xl sm:text-2xl text-2xl pt-1" >
                                    PIMS
                                </h1>
                                <h1 className="font-bold text-indigo-400 lg:text-3xl md:text-2xl sm:text-1xl text-1xl pt-3">
                                    Pimandek Integrated Management System
                                </h1>
                                <div>
                                    {
                                        pims.studentAuthor === null || pims.studentAuthor === undefined && (
                                            <>Loading...</>
                                        )
                                    }
                                    {
                                        pims?.studentAuthor == false && (
                                            <StudentAuthentication authToken={authToken} fetchData={fetchData} />
                                        )
                                    }
                                    {
                                        pims.studentAuthor && (
                                            <Navigate to={"/otms"}/>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gradient-to-br from-pink-700 via-purple-700 to-indigo-700 p-16 flex">
                <Contact />
            </div>
        </div>
    )
}

export default Home