import React, { useState } from 'react';
import { UserAuth } from '../services/authContext';
import { AiFillHome } from 'react-icons/ai';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { MdAdminPanelSettings } from 'react-icons/md';
import toast from 'react-hot-toast';

import {
    useNavigate,
    useLocation,
} from "react-router-dom";

const Navigation = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [openUser, setOpenUser] = useState(false);
    const { logOut, user, photo, pims } = UserAuth();
    let navigate = useNavigate();
    let location = useLocation();
    return (
        <>
            <div className='h-[72px] bg-gradient-to-r bg-transparent fixed w-screen z-50'>
                <div className='flex w-full h-full justify-center items-center mx-auto'>
                    <div className='absolute top-0 left-0 w-screen'>
                        {
                            user && openMenu && (<div className='block md:hidden lg:hidden absolute w-full h-full bg-gradient-to-br from-pink-100 via-indigo-200 to-purple-200 left-0 top-0 z-0'></div>)
                        }
                        <div className='relative z-10'>
                            {
                                user && !openMenu && (
                                    <button onClick={() => { setOpenMenu(!openMenu) }} className="fixed mt-4 items-center p-2 ml-3 text-sm z-40 rounded-lg  focus:outline-none focus:ring-2 text-indigo-900 hover:bg-[#4646464d] focus:ring-[#ffffff]">
                                        <span className="sr-only">Open main menu</span>
                                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                    </button>
                                )
                            }
                            {
                                user && openMenu && (
                                    <>
                                        <button onClick={() => { setOpenMenu(!openMenu) }} className="fixed mt-[-56px] md:mt-4 lg:mt-4 items-center p-2 ml-3 text-sm z-40 rounded-lg  focus:outline-none focus:ring-2 text-indigo-900 hover:bg-[#4646464d] focus:ring-[#ffffff]">
                                            <span className="sr-only">Open main menu</span>
                                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                        </button>
                                        <div className="items-center w-full z-30">
                                            <ul className="flex mt-[72px] md:mt-0 lg:mt-0 flex-col md:flex-row lg:flex-row gap-3 justify-center p-4 bg-transparent border-0 rounded-none overflow-hidden">
                                                {
                                                    !(pims?.studentAuthor) && (
                                                        <li>
                                                            {
                                                                (location.pathname === "/") && (<button onClick={() => { navigate("/"); }} className="flex gap-2 py-2 pr-4 pl-3 text-white bg-blue-700 rounded dark:text-white" aria-current="page"><AiFillHome className='text-lg' />Home</button>)
                                                            }
                                                            {
                                                                (location.pathname !== "/") && (<button onClick={() => { navigate("/"); }} className="flex gap-2 py-2 pr-4 pl-3 text-indigo-800 rounded hover:bg-[#3b57d4d5]"><AiFillHome className='text-lg' />Home</button>)
                                                            }
                                                        </li>
                                                    )
                                                }
                                                {
                                                    pims?.studentAuthor && (
                                                        <li>
                                                            {
                                                                (location.pathname.includes("/otms")) && (<button onClick={() => { navigate("/otms"); }} className="flex gap-2 py-2 pr-4 pl-3 text-white bg-blue-700 rounded dark:text-white" aria-current="page"><RiMoneyDollarCircleFill className='text-lg mt-[2px]' />Dashboard</button>)
                                                            }
                                                            {
                                                                (!location.pathname.includes("/otms")) && (<button onClick={() => { navigate("/otms"); }} className="flex gap-2 py-2 pr-4 pl-3 text-indigo-800 rounded hover:bg-[#3b57d4d5]"><RiMoneyDollarCircleFill className='text-lg mt-[2px]' />Dashboard</button>)
                                                            }
                                                        </li>
                                                    )
                                                }
                                                {
                                                    (pims?.role?.name == 'employee' || pims?.role?.name == 'admin') && (
                                                        <li>
                                                            {
                                                                (location.pathname === "/student") && (<button onClick={() => { navigate("/student"); }} className="flex gap-2 py-2 pr-4 pl-3 text-white bg-blue-700 rounded dark:text-white" aria-current="page"><AiFillHome className='text-lg' />Student</button>)
                                                            }
                                                            {
                                                                (location.pathname !== "/student") && (<button onClick={() => { navigate("/student"); }} className="flex gap-2 py-2 pr-4 pl-3 text-indigo-800 rounded hover:bg-[#3b57d4d5]"><AiFillHome className='text-lg' />Student</button>)
                                                            }
                                                        </li>
                                                    )
                                                }
                                                <li>
                                                    {
                                                        (location.pathname === "/profile") && (<button onClick={() => { navigate("/profile"); }} className="flex gap-2 py-2 pr-4 pl-3 text-white bg-blue-700 rounded dark:text-white" aria-current="page"><AiFillHome className='text-lg' />Profile</button>)
                                                    }
                                                    {
                                                        (location.pathname !== "/profile") && (<button onClick={() => { navigate("/profile"); }} className="flex gap-2 py-2 pr-4 pl-3 text-indigo-800 rounded hover:bg-[#3b57d4d5]"><AiFillHome className='text-lg' />Profile</button>)
                                                    }
                                                </li>
                                                {
                                                    pims?.role?.name == 'admin' && (
                                                        <li>
                                                            {
                                                                (location.pathname === "/admin") && (<button onClick={() => { navigate("/admin"); }} className="flex gap-2 py-2 pr-4 pl-3 text-white bg-blue-700 rounded dark:text-white" aria-current="page"><MdAdminPanelSettings className='text-lg' />Admin</button>)
                                                            }
                                                            {
                                                                (location.pathname !== "/admin") && (<button onClick={() => { navigate("/admin"); }} className="flex gap-2 py-2 pr-4 pl-3 text-indigo-800 rounded hover:bg-[#3b57d4d5]"><MdAdminPanelSettings className='text-lg' />Admin</button>)
                                                            }
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </>

                                )
                            }
                        </div>
                    </div>


                    <div className='flex text-white font-bold m-auto justify-center text-[20px] flex-grow mr-2 lg:absolute z-10'></div>
                    <div className='lg:flex lg:flex-grow z-10'></div>

                    <div
                        onMouseEnter={() => {
                            setOpenUser(true);
                        }}
                        onMouseLeave={() => {
                            setOpenUser(false);
                        }}
                        className='flex z-40 mr-3 p-2 relative'
                    >
                        {
                            (!openUser) && (
                                <div className='font-bold text-indigo-900 mr-10 mt-1 right-0 z-10'>
                                    {
                                        user?.displayName
                                    }
                                </div>
                            )
                        }
                        {
                            openUser && user && (
                                <button
                                    onClick={() => {
                                        const myPromise = logOut();
                                        toast.promise(myPromise, {
                                            loading: 'Loading',
                                            success: 'SignOut Successfully',
                                            error: 'Error when signingout',
                                        })
                                            .then((r) => r)
                                            .catch((error) => error);
                                    }}
                                    className='font-bold z-10 text-black mr-4 mt-1 right-0 pl-10 pr-10 bg-slate-100 rounded-lg active:bg-slate-200'>
                                    SignOut
                                </button>
                            )
                        }
                        {
                            user && (
                                <img className="w-8 h-8 rounded-full z-10" src={photo} alt={user?.displayName} referrerPolicy="no-referrer" />
                            )
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default Navigation