import axios from "axios";

// axios.defaults.withCredentials = true;

export const fetchStudents = async (authToken) => {
    if (authToken)
        try {
            const response = await axios.get(process.env.REACT_APP_API_IP + "/private/student/students", {
                headers: {
                    'Authorization': "Bearer " + authToken
                }
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    else {
        throw new Error("invalid authToken");
    }
}

export const updateStudent = async (authToken, id, data) => {
    if (authToken) {
        try {
            const response = await axios.put(process.env.REACT_APP_API_IP + `/private/student/students/${id}`,data,{
                headers: {
                    'Authorization': "Bearer " + authToken
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    else {
        throw new Error("invalid authToken");
    }
};

// This function will be added to your existing axios import file
export const createStudent = async (authToken, data) => {
    if (authToken) {
        try {
            const response = await axios.post(process.env.REACT_APP_API_IP + "/private/student/students", data, {
                headers: {
                    'Authorization': "Bearer " + authToken
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    else {
        throw new Error("invalid authToken");
    }
};

export const fetchClasses = async (authToken) => {
    if (authToken)
        try {
            const response = await axios.get(process.env.REACT_APP_API_IP + "/private/class/classes", {
                headers: {
                    'Authorization': "Bearer " + authToken
                }
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    else {
        throw new Error("invalid authToken");
    }
};