import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { createStudent } from '../../fetch/studentFetch';

const AddStudent = ({ authToken, getStudents }) => {
    const initialState = {
        nickname: "",
        firstName: "",
        lastName: "",
        studentID: "",
    };

    const [studentData, setStudentData] = useState(initialState);
    const [errors, setErrors] = useState({}); // State to track validation errors

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStudentData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddStudent = async () => {
        // Validate required fields
        const requiredFields = ["nickname", "firstName", "lastName", "studentID"];
        const newErrors = {};
        let isValid = true;

        requiredFields.forEach(field => {
            if (!studentData[field]) {
                newErrors[field] = "This field is required";
                isValid = false;
            }
        });

        if (!isValid) {
            setErrors(newErrors);
            return;
        }

        // If validation passes, continue with adding the student
        const myPromise = createStudent(authToken, studentData);
        toast.promise(myPromise, {
            loading: 'Adding...',
            success: (res) => {
                getStudents();
                setStudentData(initialState);
                return `Added ${res.nickname} Successfully`;
            },
            error: (err) => err.message,
        });
    };

    const renderInput = (label, prop) => (
        <div className='flex items-center border rounded-lg border-gray-300 mb-4 overflow-hidden'>
            <div className='flex-none w-1/4 bg-blue-500 text-center text-white font-semibold p-4 border-r-2 border-blue-600'>
                {label}:
            </div>
            <input className='flex-grow p-4 bg-gray-100' name={prop} value={studentData[prop]} onChange={handleChange} />
        </div>
    );

    return (
        <div className='px-5 bg-green-400 flex-grow p-8'>
            <h2 className='text-2xl font-semibold mb-6'>Add New Student</h2>
            {renderInput('Nickname', 'nickname')}
            {errors.nickname && <div className="text-red-600 mt-1">{errors.nickname}</div>}
            {renderInput('First Name', 'firstName')}
            {errors.firstName && <div className="text-red-600 mt-1">{errors.firstName}</div>}
            {renderInput('Last Name', 'lastName')}
            {errors.lastName && <div className="text-red-600 mt-1">{errors.lastName}</div>}
            {renderInput('Student ID', 'studentID')}
            {errors.studentID && <div className="text-red-600 mt-1">{errors.studentID}</div>}

            <button className='mt-6 px-6 py-3 bg-yellow-500 text-white rounded-md shadow-md hover:bg-yellow-600 transition' onClick={handleAddStudent}>
                Add Student
            </button>
        </div>
    );
}

export default AddStudent;