import React from "react";
import "./spinner.css";
import PIMS from '../../images/PIMS Logo.png';

export default function LoadingSpinner({ text }) {
    return (
        <div className="spinner-container m-auto">
            <div>
                <img src={PIMS} className="w-[200px] m-auto animate-spin" />
            </div>
        </div>
    );
}