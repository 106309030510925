import React from 'react';
import { UserAuth } from '../../services/authContext';

const Profile = () => {
    const { user,pims } = UserAuth();
    console.log(pims);
    return (
        <div className='flex-grow flex flex-col overflow-hidden'>
            <div className='h-[72px]'></div>
            <div className='flex-grow overflow-auto flex justify-center gap-10 px-10 pb-10'>
                <div className='bg-slate-100 rounded-[40px]  lg:w-[700px] md:w-[500px] sm:w-[500px] w-[500px] border-b-[15px] border-r-[15px] border-slate-200 p-5'>
                    <div className='text-[50px] font-bold text-center mb-3'>
                        Profile
                    </div>
                    <img src={user.photoURL} className='w-[25%] m-auto rounded-full border-b-[10px] border-slate-300' />
                    <div className='w-full flex mt-5 overflow-hidden rounded-lg border-b-4 border-slate-400'>
                        <div className='bg-green-400 w-[25%] text-center font-bold flex justify-center border-r-8 border-green-500'>
                            <div className='m-auto'>
                                Username :
                            </div>
                        </div>
                        <div className='bg-slate-300 w-[75%] font-bold p-5'>
                            {
                                user.displayName
                            }
                        </div>
                    </div>
                    <div className='w-full flex mt-5 overflow-hidden rounded-lg border-b-4 border-slate-400'>
                        <div className='bg-purple-400 w-[25%] text-center font-bold flex justify-center border-r-8 border-purple-500'>
                            <div className='m-auto'>
                                Role :
                            </div>
                        </div>
                        <div className='bg-slate-300 w-[75%] font-bold p-5'>
                            {
                                pims?.role?.name
                            }
                        </div>
                    </div>
                    <div className='w-full flex mt-5 overflow-hidden rounded-lg border-b-4 border-slate-400'>
                        <div className='bg-yellow-400 w-[25%] text-center font-bold flex justify-center border-r-8 border-yellow-500'>
                            <div className='m-auto'>
                                Email :
                            </div>
                        </div>
                        <div className='bg-slate-300 w-[75%] font-bold p-5'>
                            {
                                user.email
                            }
                        </div>
                    </div>
                    <div className='w-full flex mt-5 overflow-hidden rounded-lg border-b-4 border-slate-400'>
                        <div className='bg-pink-400 w-[25%] text-center font-bold flex justify-center border-r-8 border-pink-500'>
                            <div className='m-auto'>
                                UID :
                            </div>
                        </div>
                        <div className='bg-slate-300 w-[75%] font-bold p-5'>
                            {
                                user.uid
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;