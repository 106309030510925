import React from 'react';
import { Outlet } from "react-router-dom";
import Navigation from './Navigation';

const MainLayout = () => {
    return (
        <div className='flex flex-col h-screen w-screen bg-slate-900'>
            <Navigation />
            <div className='flex flex-grow overflow-auto bg-gradient-to-br from-pink-100 via-indigo-200 to-purple-200'>
                <Outlet />
            </div>
        </div>
    )
}

export default MainLayout