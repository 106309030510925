import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../../services/authContext';

const AdminPanelListClassroom = () => {
    const [classes, setClasses] = useState([]);
    const navigate = useNavigate();
    const { authToken } = UserAuth();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_IP}/private/otms/employee/getAllClasses`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }).then(response => {
            setClasses(response.data.classes);
        });
    }, []);

    return (
        <div className="flex flex-grow flex-col items-center min-h-screen bg-gray-100">
            <h1 className="text-4xl font-semibold text-blue-500 mt-10 mb-6">Classrooms</h1>
            <div className="flex-grow flex overflow-auto w-full max-w-4xl p-6 bg-white rounded-lg shadow-lg">
                <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full">
                    {classes.map((classObj) => (
                        <li className="bg-slate-100 rounded-lg p-4 text-center hover:bg-slate-200 cursor-pointer transition ease-in-out duration-200"
                            key={classObj.id}
                            onClick={() => navigate(`/otms/admin/class/${classObj.id}`)}
                        >
                            <span className="text-lg text-gray-700">{classObj.name}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AdminPanelListClassroom;
