import React, { useState, useEffect } from 'react';
import { fetchUsers, updateUser, fetchRoles } from '../../fetch/admin/userFetch'; // Adjust path accordingly
import { UserAuth } from '../../services/authContext';
import { toast } from 'react-hot-toast';

function AdminUserPanel() {
    const { authToken } = UserAuth();
    const [users, setUsers] = useState([]);
    const [originalUsers, setOriginalUsers] = useState([]); // for rollback
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const usersData = await fetchUsers(authToken);
                const rolesData = await fetchRoles(authToken);
                setOriginalUsers(usersData);
                setUsers(usersData);
                setRoles(rolesData);
            } catch (error) {
                console.error("Error fetching users or roles:", error);
            }
        }
        fetchData();
    }, [authToken]);

    const rollbackUserChanges = (uid) => {
        const originalUser = originalUsers.find(user => user.uid === uid);
        if (originalUser) {
            handleRoleChange(uid, { target: { value: originalUser.roleId.toString() } });
        }
    };

    const handleRoleChange = (uid, e) => {
        const updatedUsers = users.map(user => {
            if (user.uid === uid) {
                return { ...user, roleId: parseInt(e.target.value) };
            }
            return user;
        });
        setUsers(updatedUsers);
    };

    const handleSave = async (uid) => {
        const user = users.find(u => u.uid === uid);

        // Additional check: Do not allow changes for an admin
        if (user.role.name === "admin") {
            toast.error("Cannot modify the role of an admin.");
            rollbackUserChanges(uid);  // rollback to original value
            return;
        }

        if (user) {
            const updatePromise = updateUser(authToken, uid, { roleId: user.roleId });

            toast.promise(updatePromise, {
                loading: 'Updating user...',
                success: () => {
                    return `Role for ${user.name} updated successfully!`;
                },
                error: (err) => `Error updating user: ${err.message}`,
            });
        }
    };

    return (
        <div className='flex-grow flex flex-col'>
            <div className='h-[72px] min-h-[72px]'></div>
            <div className='flex-grow flex flex-col justify-center p-4 m-10'>
                <div className='m-auto bg-slate-50 p-10 rounded-lg'>
                    <h2 className='text-xl font-semibold mb-4'>Admin User Panel</h2>
                    <div className='overflow-x-auto'>
                        <table className='w-full'>
                            <thead>
                                <tr className='bg-gray-200'>
                                    <th className='p-2'>Name</th>
                                    <th className='p-2'>Email</th>
                                    <th className='p-2'>Role</th>
                                    <th className='p-2'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr key={user.uid} className='border-t'>
                                        <td className='p-2'>{user.name}</td>
                                        <td className='p-2'>{user.email}</td>
                                        <td className='p-2'>
                                            <select
                                                value={user.roleId}
                                                onChange={(e) => handleRoleChange(user.uid, e)}
                                                className='w-full p-2 border rounded-md'
                                                disabled={user.role.name === 'admin'} // Disable for admin users
                                            >
                                                {roles.map(role => (
                                                    <option key={role.id} value={role.id}>
                                                        {role.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td className='p-2'>
                                            <button
                                                onClick={() => handleSave(user.uid)}
                                                className={`px-4 py-2 rounded-md ${user.role.name === 'admin'
                                                    ? 'bg-red-500 text-white cursor-not-allowed'
                                                    : 'bg-blue-500 text-white'
                                                    }`}
                                                disabled={user.role.name === 'admin'}
                                            >
                                                Save Changes
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default AdminUserPanel;
