import React, { useEffect } from 'react';
import { UserAuth } from '../../services/authContext';
import StudentAuthentication from './StudentAuthentication';
import DashboardOTMS from './DashboardOTMS';
import { Navigate } from 'react-router-dom';

const OTMS = () => {
    const { pims, authToken,setPIMS } = UserAuth();
    console.log(pims);
    return (
        <div className='flex-grow flex flex-col overflow-hidden'>
            <div className='h-[72px] min-h-[72px]'></div>
            {
                pims.studentAuthor === null || pims.studentAuthor === undefined && (
                    <>Loading...</>
                )
            }
            {
                pims?.studentAuthor == false && (
                    <Navigate to={"/"}/>
                )
            }
            {
                pims.studentAuthor && (
                    <DashboardOTMS pims={pims} authToken={authToken} setPIMS={setPIMS} />
                )
            }
        </div>
    );
};

export default OTMS;
