import { useState } from 'react';
import { studentAuthorization } from '../../fetch/otmsFetch';
import Swal from 'sweetalert2';

const StudentAuthentication = ({ authToken, fetchData }) => {
    const [sid, setSID] = useState("");
    const [pass, setPass] = useState("");

    const handleSubmit = async () => {
        const swalInstance = Swal.fire({
            title: 'เข้าสู่ระบบจ่ายค่าเทอม',
            html:
                '<input id="swal-input1" class="swal2-input" placeholder="Student ID">' +
                '<input id="swal-input2" type="password" class="swal2-input" placeholder="Password">',
            focusConfirm: false,
            showCancelButton: true, // Add this line to show the "Cancel" button
            showLoaderOnConfirm: true, // Show loading spinner on confirm
            allowOutsideClick: false, // Prevent close on click outside of dialog
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value,
                    document.getElementById('swal-input2').value,
                ];
            }
        });
    
        // Listen for the Enter key
        const listenForEnterKey = (e) => {
            if (e.key === 'Enter') {
                Swal.clickConfirm();
            }
        };
    
        // Add event listener
        document.addEventListener('keydown', listenForEnterKey);
    
        // Wait for Swal to resolve
        const { value: credentials } = await swalInstance;
    
        // Remove event listener
        document.removeEventListener('keydown', listenForEnterKey);
    
        // Close loading spinner
        Swal.close();
    
        if (credentials) {
            const [enteredSid, enteredPass] = credentials;
            setSID(enteredSid);
            setPass(enteredPass);
    
            try {
                const result = await studentAuthorization(authToken, enteredSid, enteredPass);
    
                if (result) {
                    fetchData();
                    Swal.fire('Success', 'Logged in Successfully', 'success');
                }
            } catch (err) {
                Swal.fire('Error', err.response?.data?.message || err.message, 'error');
            }
        }
    };

    return (
        <div className='mt-2'>
            <button onClick={handleSubmit} className='p-5 hover:p-3 hover:bg-purple-600 ease-in-out duration-300 bg-purple-500 rounded-xl font-bold text-white'>ลงชื่อเข้าใช้ระบบจ่ายค่าเทอม</button>
        </div>
    )
}

export default StudentAuthentication;
