import React, { useState } from 'react';
import axios from 'axios';
import { UserAuth } from '../../services/authContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const InvoiceUploadPage = () => {
    const { authToken } = UserAuth();
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [formData, setFormData] = useState({ description: '' });
    const [previewImage, setPreviewImage] = useState(null);
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        const reader = new FileReader();
        reader.onloadend = () => setPreviewImage(reader.result);
        reader.readAsDataURL(e.target.files[0]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const uploadData = new FormData();
        uploadData.append('invoice', file);
        Object.keys(formData).forEach((key) => uploadData.append(key, formData[key]));

        let swalInstance = Swal.fire({
            title: 'Uploading...',
            html: 'Please wait',
            timer: 10000,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => Swal.showLoading(),
        });

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_IP}/private/otms/invoice/createInvoice`,
                uploadData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );

            Swal.fire({
                title: 'Success!',
                text: `Successfully uploaded. ${response.data.newInvoice?.description}`,
                icon: 'success',
            }).then(() => navigate(`/otms/invoice/${response.data.newInvoice?.id}`));

            setUploadStatus(`Successfully uploaded. ${response.data.newInvoice?.description}`);
        } catch (error) {
            alert(error);
            Swal.fire({ title: 'Error!', text: error.message || 'Failed to upload.', icon: 'error' });
            setUploadStatus('Failed to upload.');
        }
    };

    return (
        <div className="flex flex-col lg:flex-row md:flex-row justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md flex flex-col justify-center items-center">
                <h1 className="text-2xl font-semibold mb-4 text-center">อัพโหลดรายการ</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="description" className="block text-sm font-medium text-gray-600">คำอธิบาย</label>
                        <input
                            type="text"
                            name="description"
                            id="description"
                            placeholder="ค่าเทอม เทอม1"
                            onChange={handleChange}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="file" className="block text-sm font-medium text-gray-600">บิลใบเสร็จ</label>
                        <input
                            type="file"
                            id="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="mt-1 p-2 w-full border rounded-md"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        อัพโหลดรายการ
                    </button>
                </form>
                
            </div>
            <div className='p-6'>
                {previewImage && (
                    <div className="mt-4">
                        <img src={previewImage} alt="Preview" className='max-h-[500px] border-8 border-white rounded-md' />
                    </div>
                )}
            </div>
        </div>
    );
};

export default InvoiceUploadPage;