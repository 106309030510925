import axios from "axios";

// axios.defaults.withCredentials = true;

export const fetchUsers = async (authToken) => {
    if (!authToken) throw new Error("invalid authToken");
    try {
        const response = await axios.get(process.env.REACT_APP_API_IP + "/private/admin/usersrole", {
            headers: {
                'Authorization': "Bearer " + authToken
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateUser = async (authToken, uid, data) => {
    if (!authToken) throw new Error("invalid authToken");
    try {
        const response = await axios.put(process.env.REACT_APP_API_IP + `/private/admin/userrole/${uid}`, data, {
            headers: {
                'Authorization': "Bearer " + authToken
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchRoles = async (authToken) => {
    if (!authToken) throw new Error("invalid authToken");
    try {
        const response = await axios.get(process.env.REACT_APP_API_IP + "/private/admin/roles", {
            headers: {
                'Authorization': "Bearer " + authToken
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};