import React, { useEffect, useState } from 'react';
import { fetchStudents,fetchClasses } from '../../fetch/studentFetch';
import { UserAuth } from '../../services/authContext';
import StudentCard from './StudentCard';
import StudentDialogue from './StudentDialogue';
import AddStudent from './AddStudent';
import { toast } from 'react-hot-toast';

const Student = () => {
    const { authToken } = UserAuth();
    const [students, setStudents] = useState(undefined);
    const getStudents = async () => {
        try {
            const studentsData = await fetchStudents(authToken);
            setStudents(studentsData);
        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        getStudents();
    }, []);
    const [currentStudent, setCurrentStudent] = useState(undefined);
    const [manipulation,setManipulation] = useState(1);

    const [classes, setClasses] = useState([]);

    useEffect(() => {
        const fetchClassData = async () => {
            try {
                const fetchedClasses = await fetchClasses(authToken);
                setClasses(fetchedClasses);
            } catch (error) {
                toast.error("Failed to fetch classes");
            }
        };

        fetchClassData();
    }, [authToken]);
    return (
        <div className='flex-grow flex flex-col lg:flex-row p-[72px] justify-start gap-5 overflow-auto'>
            <div className='bg-slate-100 flex-grow rounded-md text-black overflow-auto lg:max-w-[50%] flex flex-col min-h-screen lg:min-h-full'>
                <div className='flex bg-slate-400'>
                    <div className='p-1 ml-2 flex justify-center'>
                        <div className='m-auto'>
                            id
                        </div>
                    </div>
                    <div className='flex-grow p-2 grid grid-cols-6 gap-2 text-center'>
                        <div className='flex-grow overflow-hidden'>
                            studentID
                        </div>
                        <div className='flex-grow overflow-hidden'>
                            nickname
                        </div>
                        <div className='flex-grow break-all'>
                            nameTitle
                        </div>
                        <div className='flex-grow break-all'>
                            firstName
                        </div>
                        <div className='flex-grow break-all'>
                            lastName
                        </div>
                        <div className='flex-grow break-all'>
                            class
                        </div>
                    </div>
                </div>
                <div className='flex-grow [&>*:nth-child(odd)]:bg-slate-100 overflow-auto [&>*:nth-child(even)]:bg-slate-200'>
                    {
                        students?.sort((a, b) => b.id - a.id)?.map((item, index) => {
                            // Find the class for the student
                            const matchedClass = classes?.find((classItem) => classItem.id === item?.classId);
                            
                            // Get the class name, if the class is found
                            const className = matchedClass?.name || '';
                        
                            return (
                                <StudentCard 
                                    key={index} 
                                    student={{ ...item, className: className }} 
                                    setCurrentStudent={setCurrentStudent} 
                                    setManipulation={setManipulation} 
                                    isIndex={item.id === currentStudent?.id}
                                />
                            );
                        })
                    }
                </div>
            </div>
            <div className='flex-grow rounded-md text-black lg:max-w-[50%] min-h-screen lg:min-h-full flex flex-col'>
                <div className='flex gap-1'>
                    <button className='p-2 bg-green-400 rounded-t-lg font-bold' onClick={()=>{setManipulation(1)}}>
                        Add Student
                    </button>
                    <button className='p-2 px-3 bg-slate-100 rounded-t-lg font-bold' onClick={()=>{setManipulation(2)}}>
                        Set Student
                    </button>
                </div>
                <div className='flex-grow flex overflow-auto w-full h-full rounded-b-md'>
                    {
                        manipulation === 1 && <AddStudent authToken={authToken} getStudents={getStudents}/>
                    }
                    {
                        manipulation === 2 && <StudentDialogue student={currentStudent} authToken={authToken} getStudents={getStudents} classes={classes}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default Student