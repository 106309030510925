import React from 'react'

const Aboutme = () => {
  return (
    <div className='bg-slate-600 flex-grow'>
      Aboutme
    </div>
  )
}

export default Aboutme