import { Outlet,Navigate } from "react-router-dom";
import { UserAuth } from "../../../services/authContext";

const OTMSProtectedRoute = () => {
    const {pims} = UserAuth();
    if(pims?.role?.name != 'admin' && pims?.role?.name != 'employee') return <Navigate to={"/otms"}/>;
    return (
        <Outlet/>
    );
}

export default OTMSProtectedRoute