import React, { useEffect } from 'react';
import { UserAuth } from '../services/authContext';
import LoadingSpinner from '../components/loading/LoadingSpinner';
import PIMS from '../images/PIMS Logo.png';
import { BsFacebook, BsGoogle } from 'react-icons/bs';
import toast from 'react-hot-toast';

const ProtectedRoute = ({ children, src, callback }) => {
    const { user, pims, setPIMS, authToken } = UserAuth();

    if (user) {
        if (pims) {
            return children;
        }
        else if (pims === null) {
            return (
                <div className='flex flex-grow justify-center flex-col bg-gradient-to-br from-pink-100 via-indigo-200 to-purple-200'>
                    <div>
                        <img src={PIMS} className="w-[200px] m-auto" />
                    </div>
                </div>
            )
        }
        else {
            <div className='flex flex-grow justify-center flex-col bg-gradient-to-br from-pink-100 via-indigo-200 to-purple-200'>
                <div>
                    <LoadingSpinner />
                </div>
            </div>
        }
    }
    else if(user === null){
        return <LoginTab />;
    }
    else {
        return (
            <div className='flex flex-grow justify-center flex-col bg-gradient-to-br from-pink-100 via-indigo-200 to-purple-200'>
                <div>
                    <LoadingSpinner />
                </div>
            </div>
        )
    }
};

const LoginTab = () => {
    const { signInGoogle, setPhoto, signInFacebook } = UserAuth();
    const signInWithGoogle = () => {
        const myPromise = signInGoogle().then(result => {
            const photoUrl = result.user.photoURL;
            setPhoto(photoUrl);
        })
        toast.promise(myPromise, {
            loading: 'Loading',
            success: 'Login Successfully',
            error: 'Error when logging in',
        })
            .then((r) => r)
            .catch(async (error) => {
                console.log(error);
                return error;
            });
    }
    const signInWithFacebook = () => {
        const myPromise = signInFacebook().then(result => {
            const photoUrl = result.user.photoURL;
            setPhoto(photoUrl);
        })
        toast.promise(myPromise, {
            loading: 'Loading',
            success: 'Login Successfully',
            error: 'Error when logging in',
        })
            .then((r) => r)
            .catch((error) => { console.log(error); return error });
    }
    return (
        <div className='flex flex-grow bg-gradient-to-br from-pink-100 via-indigo-200 to-purple-200 p-14'>
            <div className="bg-transparent border-2 border-slate-100 flex-grow rounded-[40px] ">
                <div className="w-full h-full relative">
                    <div className="bg-white opacity-40 rounded-[40px] absolute w-full h-full"></div>
                    <div className="relative text-center w-full h-full flex justify-center p-7 lg:p-14 md:p-7 sm:p-7 gap-14">
                        <div className='bg-transparent border-2 border-slate-100 flex-grow rounded-[40px] hidden lg:block md:hidden sm:hidden max-w-[40%]'>
                            <div className="w-full h-full relative">
                                <div className="bg-gradient-to-br from-pink-700 via-purple-700 to-indigo-700 rounded-[40px] absolute w-full h-full"></div>
                                <div className="relative text-center w-full h-full flex flex-col justify-center">
                                    <div>
                                        <img src={PIMS} className="w-[200px] m-auto" />
                                        <h1 className="font-bold text-indigo-100 lg:text-6xl md:text-4xl sm:text-2xl text-2xl mt-10">
                                            PIMS
                                        </h1>
                                        <h1 className="font-bold text-indigo-300 lg:text-4xl md:text-2xl sm:text-1xl text-1xl">
                                            Pimandek Integrated Management System
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-transparent border-2 border-slate-100 flex-grow rounded-[40px]'>
                            <div className="w-full h-full relative">
                                <div className="bg-white opacity-20 rounded-[40px] absolute w-full h-full"></div>
                                <div className="relative text-center w-full h-full flex flex-col justify-center">
                                    <div className='p-4 flex flex-col flex-grow justify-center'>
                                        <div>
                                            <img src={PIMS} className="w-[150px] m-auto block lg:hidden md:block sm:block" />
                                            <h1 className="font-bold text-indigo-700 lg:text-6xl md:text-4xl text-4xl">
                                                Log In
                                            </h1>
                                            <h1 className="mt-4 font-bold text-indigo-400 text-2xl">
                                                to the future of management system
                                            </h1>
                                        </div>
                                        <div className='bg-white rounded-[40px] flex-grow m-0 lg:m-8 md:m-4 sm:m-0 flex flex-col justify-center'>
                                            <div className='flex flex-col gap-8'>
                                                {/* <button className="bg-blue-600 font-bold text-white rounded-md z-50 max-w-[400px] min-w-[280px] m-auto p-5" onClick={signInWithFacebook}>
                                                    <div className='flex gap-2 text-[20px]'><BsFacebook className='text-white text-[30px]' />
                                                        Login With Facebook
                                                    </div>
                                                </button> */}
                                                <button onClick={signInWithGoogle} className="bg-gradient-to-br from-purple-300 via-green-300 to-red-300 font-bold text-white rounded-md z-50 min-w-[280px] max-w-[400px] m-auto p-5">
                                                    <div className='flex gap-2 text-[20px]'><BsGoogle className='text-white text-[30px]' />
                                                        Login With Google
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProtectedRoute;