import { auth, facebookProvider, provider } from './firebase';
import { createContext, useContext, useEffect, useState } from 'react';
import {
    onAuthStateChanged,
    signOut,
    signInWithPopup,
    fetchSignInMethodsForEmail,
    FacebookAuthProvider,
    linkWithCredential,
    signInWithCredential
} from "firebase/auth";
import axios from 'axios';
import toast from 'react-hot-toast';
import { checkStudentAuthor } from '../fetch/otmsFetch';

export const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(undefined);
    const [photo, setPhoto] = useState(undefined);
    const [authToken, setAuthToken] = useState(undefined);
    const [pims, setPIMS] = useState(undefined);

    const signInGoogle = async () => {
        try {
            return await signInWithPopup(auth, provider);
        }
        catch (error) {
            console.log("Error from sign in with google");
            console.log(error);
            console.log(error.code);
            if (error.code === 'auth/account-exists-with-different-credential') {
                const email = error.email;
                const credential = error.credential;
                // console.log(email,credential);
                const methods = await fetchSignInMethodsForEmail(auth, email);
                // console.log(methods);
                if (methods[0] === 'facebook.com') {
                    const facebookProvider = new FacebookAuthProvider();
                    const facebookUserCredential = await signInWithPopup(facebookProvider);
                    await facebookUserCredential.user.linkWithCredential(credential);
                    return facebookUserCredential;
                }
                return credential;
            }
        }
    }

    const signInFacebook = async () => {
        try {
            const facebookProvider = new FacebookAuthProvider();
            const credential = await signInWithPopup(auth, facebookProvider);
            return credential;
        }
        catch (error) {
            if (error.code === 'auth/account-exists-with-different-credential') {
                const email = error.customData.email;
                const credential = error.customData._tokenResponse;
                // console.log(email,credential);
                const googleUserCredential = await signInWithPopup(provider);
                // console.log(googleUserCredential);
                await linkWithCredential(googleUserCredential,credential);
                return googleUserCredential;
            }
        }
    }

    const logOut = async () => {
        setPhoto(undefined);
        setPIMS(undefined);
        return signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setPIMS(undefined);
                setUser(currentUser);
                setAuthToken(currentUser?.accessToken);
                setPhoto(currentUser.photoURL);
            }
            else {
                setUser(null);
                setPIMS(undefined);
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    const fetchData = async () => {
        const response = await checkStudentAuthor(authToken);
        setPIMS((old_pims)=>{return { ...old_pims, studentAuthor: response.studentAuthor }});
    };

    useEffect(() => {
        if (user) {
            // console.log(user);
            auth.currentUser.getIdToken().then((idToken) => {
                axios.get(process.env.REACT_APP_API_IP + "/private/auth", {
                    headers: {
                        'Authorization': "Bearer " + idToken
                    }
                })
                    .then(response => {
                        if (response.status === 200) {
                            setPIMS(response.data);
                            fetchData();
                        }
                        else {
                            toast.error("Unauthorized");
                            setPIMS(null);
                        }
                        // console.log(response.data);
                    })
                    .catch(err => {
                        setPIMS(null);
                        // console.error(err);
                        toast.error(err.message);
                    });
            });
        }
    }, [user]);
    // useEffect(() => {
    //     const fetchDataAsync = async () => {
    //         if (authToken) {
    //             await 
    //         }
    //     };
    //     fetchDataAsync();
    // }, [authToken]);
    return (
        <UserContext.Provider value={{ user, logOut, signInGoogle, signInFacebook, photo, setPhoto, authToken, pims, setPIMS }}>
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(UserContext);
};