import React, { useEffect, useState } from 'react';
import { updateStudent } from '../../fetch/studentFetch';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

const StudentDialogue = ({ student, authToken, getStudents, classes }) => {
    const initialState = {
        nickname: "",
        nameTitle: "",
        firstName: "",
        lastName: "",
        citizenID: "",
        studentID: "",
        classId: "",
        birthdate: moment().format(),
    };

    useEffect(() => {
        if (student) {
            setStudentData({
                nickname: student.nickname || "",
                nameTitle: student.nameTitle || "",
                firstName: student.firstName || "",
                lastName: student.lastName || "",
                citizenID: student.citizenID ? String(student.citizenID) : "", // Convert to string
                studentID: student.studentID ? String(student.studentID) : "", // Convert to string
                classId: student?.classId || "",
                birthdate: student.birthdate ? moment(student.birthdate).format() : moment().format()
            });
        } else {
            setStudentData(initialState); // Make sure to set to initialState when student is not available
        }
    }, [student]);

    const [studentData, setStudentData] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStudentData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleUpdateStudent = async () => {
        if (student) {
            const { class: _, ...updatedStudent } = student;

            // Convert the selected birthdate to UTC midnight time
            const formattedBirthdayDatePart = studentData.birthdate
                ? moment.utc(studentData.birthdate).format('YYYY-MM-DDT')
                : "";

            // Get the current UTC time
            const formattedBirthdayTimePart = moment.utc().format('HH:mm:ss.SSS') + 'Z';

            const formattedBirthday = formattedBirthdayDatePart
                ? formattedBirthdayDatePart + formattedBirthdayTimePart
                : "";

            const myPromise = updateStudent(authToken, student.id, {
                ...updatedStudent,
                ...studentData,
                birthdate: formattedBirthday
            });

            toast.promise(myPromise, {
                loading: 'Updating...',
                success: (res) => {
                    getStudents(); // Fetch the updated student list
                    return `Updated ${res.nickname} Successfully`;
                },
                error: (err) => err.message,
            });
        } else {
            toast.error("Please Select Student");
        }
    }


    const renderInput = (label, prop) => (
        <div className='flex items-center border rounded-lg border-slate-400 mb-4 overflow-hidden'>
            <div className='flex-none w-1/4 bg-green-400 text-center font-bold p-4 border-r-2 border-green-500'>
                {label} :
            </div>
            <input className='flex-grow p-4 bg-slate-300 font-bold' name={prop} value={studentData[prop]} onChange={handleChange} />
        </div>
    );

    const renderDateTimeInput = (label, prop) => (
        <div className='flex items-center border rounded-lg border-slate-400 mb-4 overflow-hidden'>
            <div className='flex-none w-1/4 bg-green-400 text-center font-bold p-4 border-r-2 border-green-500'>
                {label} :
            </div>
            <input
                type="date"
                name={prop}
                className='flex-grow p-4 bg-slate-300 font-bold'
                value={moment(studentData[prop]).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD')}
                onChange={handleChange}
            />
        </div>
    );

    return (
        <div className='px-5 py-5 bg-slate-100 flex flex-col flex-grow'>
            {renderInput('NickName', 'nickname')}
            {renderInput('Name Title', 'nameTitle')}
            {renderInput('First Name', 'firstName')}
            {renderInput('Last Name', 'lastName')}
            {renderInput('Citizen ID', 'citizenID')}
            {renderInput('Student ID', 'studentID')}
            {renderDateTimeInput('Birthdate', 'birthdate')}
            <div className='flex items-center border rounded-lg border-slate-400 mb-4'>
                <div className='flex-none w-1/4 bg-green-400 text-center font-bold p-4 border-r-2 border-green-500'>
                    Class :
                </div>
                <select
                    name="classId"
                    value={studentData.classId || ''} // default to an empty string if not set
                    onChange={handleChange}
                    className='flex-grow p-4 bg-slate-300 font-bold'>
                    <option value=''></option>  {/* Default option */}
                    {classes.map(cls => (
                        <option key={cls.id} value={cls.id}>{cls.name}</option>
                    ))}
                </select>
            </div>

            <button className='p-4 bg-yellow-500 mt-5 rounded shadow-md hover:bg-yellow-600 active:bg-yellow-700 transition' onClick={handleUpdateStudent}>
                Update Student Info
            </button>
        </div>
    )
}

export default StudentDialogue;
