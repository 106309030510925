import axios from "axios";

// axios.defaults.withCredentials = true;

export const checkStudentAuthor = async (authToken) => {
    if (authToken) {
        try {
            const response = await axios.get(process.env.REACT_APP_API_IP + "/private/otms", {
                headers: {
                    'Authorization': "Bearer " + authToken
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    } else {
        throw new Error("Invalid authToken");
    }
};

export const studentAuthorization = async (authToken, studentID, password) => {
    if (authToken) {
        if (studentID && password) {
            try {
                const data = {
                    studentID: studentID,
                    password: password
                }
                const response = await axios.post(process.env.REACT_APP_API_IP + "/private/otms/auth", data, {
                    headers: {
                        'Authorization': "Bearer " + authToken
                    }
                });
                return response.data;
            } catch (error) {
                throw error;
            }
        }
        else {
            throw new Error("Invalid StudentID and Password");
        }
    } else {
        throw new Error("Invalid authToken");
    }
};

export const studentLogout = async (authToken, studentID) => {
    if (authToken) {
        if (studentID) {
            try {
                const data = {
                    studentID: studentID,
                }
                const response = await axios.post(process.env.REACT_APP_API_IP + "/private/otms/logout", data, {
                    headers: {
                        'Authorization': "Bearer " + authToken
                    }
                });
                return response.data;
            } catch (error) {
                throw error;
            }
        }
        else {
            throw new Error("Invalid StudentID");
        }
    }
    else {
        throw new Error("Invalid authToken");
    }
};