import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UserAuth } from '../../../services/authContext';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const AdminPanelInvoice = () => {
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const { authToken } = UserAuth();
    const [invoice, setInvoice] = useState(null);
    const { invoiceId } = useParams();
    const fetchInvoice = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_IP}/private/otms/employee/getInvoice/${invoiceId}`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );

            setInvoice(response.data.invoice);
            setIsImageLoading(false);

            // Fetch the invoice image
            if (response.data.invoice && response.data.invoice.invoiceImageUrl) {
                setIsImageLoading(true);
                const imgResponse = await axios.get(
                    `${process.env.REACT_APP_API_IP}/private/otms/employee/getInvoiceImage/${response.data.invoice.invoiceImageUrl}`,
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                        responseType: 'blob'
                    }
                );

                const url = window.URL.createObjectURL(new Blob([imgResponse.data]));
                setImageSrc(url);
                setIsImageLoading(false);
            }

        } catch (error) {
            console.error('Error fetching invoice:', error);
            setIsImageLoading(false);
        }
    };
    useEffect(() => {
        fetchInvoice();
    }, [invoiceId, authToken]);

    const updateInvoiceStatus = async (invoiceId, newStatus) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `You are about to ${newStatus.toLowerCase()} this invoice.`,
            icon: (newStatus=='APPROVED')?'success':'error',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (result.isConfirmed) {
            axios.post(
                `${process.env.REACT_APP_API_IP}/private/otms/employee/updateInvoiceStatus`,
                { invoiceId, newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            ).then(response => {
                if (response.data.message === 'Invoice status updated') {
                    Swal.fire('Updated!', `Invoice has been ${newStatus.toLowerCase()}.`, 'success').then(async () => {
                        try{
                            const response = await axios.get(
                                `${process.env.REACT_APP_API_IP}/private/otms/employee/getInvoice/${invoiceId}`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${authToken}`,
                                    },
                                }
                            );
                
                            setInvoice(response.data.invoice);
                        }
                        catch(e){

                        }
                    })
                }
            }).catch(error => {
                Swal.fire('Error!', 'Something went wrong.', 'error');
            });
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <div className={`${invoice?.payStatus === 'APPROVED' ? 'bg-green-100' : (invoice?.payStatus === 'PENDING')?'bg-white':'bg-red-100'} rounded-lg shadow-lg p-6 w-full max-w-md`}>
                {invoice ? (
                    <>
                        <div className="text-center mb-6">
                            <h1 className="text-2xl font-semibold mb-2">Invoice Details</h1>
                            <p className="text-gray-500">Invoice ID: {invoice?.id}</p>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <p><strong className="text-gray-700">Description:</strong> {invoice?.description}</p>
                            <p><strong className="text-gray-700">Pay Status:</strong> {invoice?.payStatus}</p>
                            <p><strong className="text-gray-700">Date:</strong> {new Date(invoice?.datetime).toLocaleString()}</p>
                            <div>
                                <button className="bg-green-500 text-white p-2 rounded hover:bg-green-600" onClick={() => updateInvoiceStatus(invoice?.id, 'APPROVED')}>Approve</button>
                                <button className="bg-red-500 text-white p-2 rounded hover:bg-red-600 ml-4" onClick={() => updateInvoiceStatus(invoice?.id, 'REJECTED')}>Reject</button>
                            </div>
                        </div>
                        {isImageLoading ? (
                            <div className="w-[200px] h-[200px] bg-slate-400 animate-pulse"></div>
                        ) : imageSrc && (
                            <img className="mt-6 mx-auto" src={imageSrc} alt="Invoice" width="200" />
                        )}
                    </>
                ) : (
                    <div className="text-center">
                        <p>Loading invoice...</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminPanelInvoice;
