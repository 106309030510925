import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import React from "react";
import MainLayout from './layouts/MainLayout';
import Home from './pages/main/Home';
import { AuthContextProvider } from './services/authContext';
import Contact from './pages/contact/Contact';
import Aboutme from './pages/aboutme/Aboutme';
import Profile from './pages/profile/Profile';
import ProtectedRoute from './services/ProtectedRoute';
import Student from './pages/student/Student';
import NotFound from './pages/404/NotFound';
import AdminProtectedRoute from './services/AdminProtectedRoute';
import EmployeeProtectedRoute from './services/EmployeeProtectedRoute';
import AdminUserPanel from './pages/admin/AdminUserPanel';
import AdminLayout from './pages/admin/AdminLayout';
import OTMS from './pages/otms/OTMS';
import InvoiceUploadPage from './pages/otms/InvoiceUploadPage';
import InvoiceListPage from './pages/otms/InvoiceListPage';
import InvoiceDetailPage from './pages/otms/InvoiceDetailPage';
import OTMSProtectedRoute from './pages/otms/admin/OTMSProtectedRoute';
import AdminPanelListClassroom from './pages/otms/admin/AdminPanelListClassroom';
import AdminPanelClassroom from './pages/otms/admin/AdminPanelClassroom';
import AdminPanelInvoice from './pages/otms/admin/AdminPanelInvoice';

function App() {
  return (
    <div>
      <Toaster />
      <React.Suspense fallback={<div>Loading...</div>}>
        <AuthContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route path="contact" element={<ProtectedRoute><Contact/></ProtectedRoute>} />
                <Route path="about" element={<Aboutme/>} />
                <Route path="profile" element={<ProtectedRoute><Profile/></ProtectedRoute>} />
                <Route path="otms" element={<ProtectedRoute><OTMS/></ProtectedRoute>} >
                  <Route index element={<InvoiceListPage/>}/>
                  <Route path="upload" element={<InvoiceUploadPage/>}/>
                  <Route path="invoice/:id" element={<InvoiceDetailPage/>}/>
                  <Route path="admin" element={<OTMSProtectedRoute/>}>
                    <Route index element={<AdminPanelListClassroom/>}/>
                    <Route path='class/:classId' element={<AdminPanelClassroom/>}/>
                    <Route path='invoice/:invoiceId' element={<AdminPanelInvoice/>}/>
                  </Route>
                </Route>
                <Route path="student" element={<ProtectedRoute><EmployeeProtectedRoute><Student/></EmployeeProtectedRoute></ProtectedRoute>} />
                <Route path='admin' element={<ProtectedRoute><AdminLayout/></ProtectedRoute>}>
                  <Route index element={<AdminUserPanel/>} />
                </Route>
                <Route path="*" element={<NotFound/>} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthContextProvider>
      </React.Suspense>
    </div>
  );
}

export default App;
