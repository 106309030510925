import { UserAuth } from "./authContext";
import PIMSlogo from '../images/PIMS Logo.png';

const AdminProtectedRoute = ({ children }) => {
    const { pims } = UserAuth();
    if (pims?.role?.name !== 'admin') {
        return (
            <div className="flex-grow overflow-auto">
                <div className="w-screen h-screen bg-gradient-to-br from-pink-100 via-indigo-200 to-purple-200 p-16">
                    <div className="bg-transparent border-2 border-slate-100 w-full h-full rounded-[40px]">
                        <div className="w-full h-full relative">
                            <div className="bg-white opacity-40 rounded-[40px] absolute w-full h-full"></div>
                            <div className="relative text-center w-full h-full flex flex-col justify-center">
                                <div>
                                    <img src={PIMSlogo} className="w-[200px] m-auto" />
                                    <h1 className="font-bold text-indigo-700 lg:text-6xl md:text-4xl sm:text-2xl text-2xl pt-1" >
                                        You Need Admin Permission
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return children;
}

export default AdminProtectedRoute