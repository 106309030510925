import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UserAuth } from '../../services/authContext';
import { useNavigate } from 'react-router-dom';

const InvoiceListPage = () => {
    const { authToken } = UserAuth();
    const [invoices, setInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const fetchInvoices = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_IP}/private/otms/invoice/getInvoices`,
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );
                setInvoices(response.data.invoices);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching invoices:', error);
                setIsLoading(false);
            }
        };

        fetchInvoices();
    }, [authToken]);

    return (
        <div className="flex flex-col items-center min-h-screen">
            <h1 className="text-4xl font-semibold text-blue-500 mt-10 mb-6">รายการทั้งหมด</h1>

            {isLoading ? (
                <div className="text-2xl text-gray-500">Loading...</div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 p-4 w-full max-w-6xl">
                    {invoices.length > 0 ? (
                        invoices.map((invoice) => <InvoiceList key={invoice.id} invoice={invoice} />)
                    ) : (
                        <div className="text-xl text-red-500">
                            No Invoices Found
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const InvoiceList = ({ invoice }) => {
    const navigate = useNavigate();
    return (
        <div
            className={`flex h-[350px] flex-col ${invoice.payStatus === 'APPROVED' ? 'bg-green-100' : (invoice.payStatus === 'PENDING') ? 'bg-white' : 'bg-red-100'} rounded-lg shadow-lg cursor-pointer overflow-hidden hover:bg-blue-50`}
            onClick={() => { navigate(`/otms/invoice/${invoice.id}`) }}
        >
            <div className='p-6 flex-grow flex flex-col'>
                <h2 className="text-base text-right font-semibold mb-2">บิลเลขที่ #{invoice.id}</h2>
                <div className='min-h-[2px] bg-black mb-2'></div>
                <p className="text-base text-gray-700"><strong>ชื่อ:</strong> {invoice.student?.firstName} {invoice.student?.lastName}</p>
                <p className="text-base text-gray-700 mb-2"><strong>รหัสนักเรียน:</strong> {invoice.student?.studentID}</p>
                <p className="text-base text-gray-700"><strong>คำอธิบาย:</strong> {invoice.description}</p>
                <p className={`text-md ${invoice.payStatus === 'APPROVED' ? 'text-green-600' : (invoice.payStatus === 'PENDING') ? 'text-black' : 'text-red-600'}`}><strong>สถานะ:</strong> {statusTranslator(invoice.payStatus)}</p>
                <p className="flex-grow flex flex-col justify-end text-md text-gray-700"><strong>เวลา:</strong> {new Date(invoice.datetime).toLocaleString()}</p>
            </div>
            <div className='min-h-[20px] bg-purple-500'></div>
        </div>
    );
}

const statusTranslator = (status) => {
    if (status == "APPROVED") return "ถูกอนุมัติ";
    if (status == "REJECTED") return "ถูกปฏิเสธ";
    return "กำลังดำเนินการ";
}

export default InvoiceListPage;
