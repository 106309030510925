import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { UserAuth } from '../../services/authContext';

const InvoiceDetailPage = () => {
    const { authToken } = UserAuth();
    const { id } = useParams();
    const [invoice, setInvoice] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        const fetchInvoice = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_IP}/private/otms/invoice/getInvoice/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );

                setInvoice(response.data.invoice);
                setIsLoading(false);

                // Fetch the invoice image
                if (response.data.invoice && response.data.invoice.invoiceImageUrl) {
                    setIsImageLoading(true);
                    const imgResponse = await axios.get(
                        `${process.env.REACT_APP_API_IP}/private/otms/invoice/getInvoiceImage/${response.data.invoice.invoiceImageUrl}`,
                        {
                            headers: {
                                Authorization: `Bearer ${authToken}`,
                            },
                            responseType: 'blob'
                        }
                    );

                    const url = window.URL.createObjectURL(new Blob([imgResponse.data]));
                    setImageSrc(url);
                    setIsImageLoading(false);
                }

            } catch (error) {
                console.error('Error fetching invoice:', error);
                setImageError(true);
                setIsLoading(false);
            }
        };

        fetchInvoice();
    }, [id, authToken]);

    return (
        <div className="flex flex-col items-center h-full">
            {isLoading ? (
                <div className="text-2xl text-gray-500">Loading...</div>
            ) : (
                invoice && (
                    <div className={`border ${invoice.payStatus === 'APPROVED' ? 'bg-green-100' : (invoice.payStatus === 'PENDING') ? 'bg-white' : 'bg-red-100'} overflow-auto rounded-lg w-full max-w-2xl flex flex-col lg:flex-row md:flex-row gap-6 shadow-lg`}>
                        <div className='flex flex-col h-full p-6'>
                            <h1 className="text-4xl text-blue-500 font-bold mb-6">บิลเลขที่ #{id}</h1>
                            <div className='min-h-[2px] bg-black mb-2'></div>
                            <p className="text-lg text-gray-700 mb-2"><strong>ชื่อ:</strong> <div>{invoice.student?.firstName} {invoice.student?.lastName}</div></p>
                            <p className="text-lg text-gray-700 mb-2"><strong>รหัสนักเรียน:</strong> <div>{invoice.student?.studentID}</div></p>
                            <p className="text-lg text-gray-700 mb-2"><strong>คำอธิบาย:</strong> <div>{invoice.description}</div></p>
                            <p className={`text-lg ${invoice.payStatus === 'APPROVED' ? 'text-green-600' : (invoice.payStatus === 'PENDING') ? 'text-black' : 'text-red-600'}`}><strong>สถานะ:</strong> {statusTranslator(invoice.payStatus)}</p>
                            <p className="text-lg flex-grow flex flex-col justify-end text-gray-700"><strong className='mt-auto'>เวลา:</strong> {new Date(invoice.datetime).toLocaleString()}</p>
                        </div>
                        <div className='flex-grow flex justify-center p-6'>
                            {isImageLoading ? (
                                ((imageError) ? <div className="w-32 h-32 bg-red-600 animate-pulse"></div> : <div className="w-32 h-32 bg-slate-400 animate-pulse"></div>)
                            ) : imageSrc ? (
                                <div className='mx-auto'>
                                    <img src={imageSrc} alt="Invoice" width="200" className='relative right-0' />
                                </div>
                            ) : (
                                <div className="w-32 h-32 flex items-center justify-center text-red-600">
                                    <i className="fas fa-exclamation-triangle text-6xl"></i>
                                </div>
                            )}
                        </div>
                        <div className='lg:min-w-[20px] md:min-w-[20px] min-h-[20px] bg-purple-500'></div>
                    </div>
                )
            )}
        </div>
    );
};

const statusTranslator = (status) => {
    if(status == "APPROVED") return "ถูกอนุมัติ";
    if(status == "REJECTED") return "ถูกปฏิเสธ";
    return "กำลังดำเนินการ";
}

export default InvoiceDetailPage;