import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { UserAuth } from '../../../services/authContext';

const AdminPanelClassroom = () => {
    const [invoices, setInvoices] = useState([]);
    const { classId } = useParams();
    const { authToken } = UserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (authToken) {
            axios.get(`${process.env.REACT_APP_API_IP}/private/otms/employee/getInvoicesByClass/${classId}`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }).then(response => {
                    setInvoices(response.data.invoices);
                });
        }
    }, [authToken]);

    return (
        <div className="flex flex-grow flex-col items-center min-h-screen bg-gray-100 p-6">
            <h1 className="text-4xl font-semibold text-blue-500 mt-10 mb-6">
                Invoices in Classroom {classId}
            </h1>
            <div className="w-full max-w-4xl p-6 bg-white rounded-lg shadow-lg">
                <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full">
                    {invoices.map((invoice) => (
                        <li 
                            className={`${invoice.payStatus === 'APPROVED' ? 'bg-green-100' : (invoice.payStatus === 'PENDING')?'bg-white':'bg-red-100'} rounded-lg p-4 text-center hover:bg-slate-200 cursor-pointer transition ease-in-out duration-200`}
                            key={invoice.id} 
                            onClick={() => navigate(`/otms/admin/invoice/${invoice.id}`)}
                        >
                            <span className="text-lg text-gray-700">
                                {invoice.description} - {invoice.payStatus}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AdminPanelClassroom;
