import React, { useState } from "react";
import emailjs from 'emailjs-com';
import { UserAuth } from '../../services/authContext';
import { toast } from "react-hot-toast";
import PIMSlogo from '../../images/PIMS Logo.png';

function Contact() {
  const { user } = UserAuth();

  const [name, setName] = useState(user.displayName);
  const [email, setEmail] = useState(user.email);
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target);
    emailjs
      .sendForm(
        "service_78dqrec",
        "template_6zgpwah",
        e.target,
        "WW4L9o9er21vjA2AT"
      )
      .then(
        (result) => {
          toast.success(result.text);
          console.log(result.text);
          setMessage("");
        },
        (error) => {
          toast.error(error.text);
          console.log(error.text);
        }
      );
  }
  return (


    <div className="bg-purple-100 flex-grow">

      <div className="mx-auto px-8 p-10 rounded-xl bg-white shadow-lg">
        <img src={PIMSlogo} className="w-[100px] m-auto pt-10 animate-bounce " />
        <h1 className="text-3xl text-center font-bold mb-4 text-purple-700 bg-gradient-to-r from-purple-100 via-purple-300 to-purple-100 rounded-2xl p-3 mx-20">
          Contact us
        </h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mb-4 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-purple-500"
            placeholder="Enter your name"
            readOnly
            required
          />
          <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className="mb-4 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-purple-500"
            placeholder="Enter your email"
            required
            readOnly
          />
          <label htmlFor="message" className="block text-gray-700 font-bold mb-2">
            Message
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            name="message"
            className="mb-4 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-purple-500"
            placeholder="Enter your message"
            rows={4}
            required
          ></textarea>
          <div className="flex justify-center">
            <input
              type="submit"
              className="bg-purple-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
              value={"Submit"}
            />
          </div>
          <div className="font-bold pt-10 p-2 text-xs text-purple-400 rounded-lg text-center">
            --or--
          </div>

          <div className="text-center pt-5 font-bold hover:text-purple-400 text-purple-600">
            📞 : 053 274 002
            <div>📧 : info@pimandek.ac.th</div>
          </div>


        </form>
      </div>
    </div>
  );
};


export default Contact