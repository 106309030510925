import React from 'react'
import { studentLogout } from '../../fetch/otmsFetch';
import { Outlet, useNavigate } from 'react-router-dom';
import { checkStudentAuthor } from '../../fetch/otmsFetch';
import { FaFileUpload, FaClipboardList, FaAngular } from 'react-icons/fa';
import { RiLogoutBoxRFill } from 'react-icons/ri';
import { IoChevronBackSharp } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2'

const DashboardOTMS = ({ pims, authToken, setPIMS }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const fetchData = async () => {
        const response = await checkStudentAuthor(authToken);
        setPIMS((old_pims)=>{return { ...old_pims, studentAuthor: response.studentAuthor }});
    };
    const handleLogout = async () => {
        const result = await Swal.fire({
            title: 'Logout?',
            text: pims?.studentAuthor?.student?.firstName + " " + pims?.studentAuthor?.student?.lastName,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log me out!'
        });

        if (result.isConfirmed) {
            try {
                await studentLogout(authToken, pims?.studentAuthor?.student?.id);
                await fetchData();
                Swal.fire({
                    title: 'Logged Out!',
                    text: 'You have been logged out successfully.',
                    icon: 'success'
                });
            }
            catch (e) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
        }
    }
    const navigation = () => {
        if (location.pathname.includes('/otms/invoice/')) {
            return (
                <>
                    <div className='px-4 py-0 md:py-4 lg:py-4 flex justify-center border-blue-500 bg-slate-50 lg:hover:border-l-4 md:hover:border-l-4 rounded-lg my-1 md:my-0 lg:my-0 ease-in-out duration-300 hover:text-[25px]' onClick={() => { navigate("/otms") }}>
                        <IoChevronBackSharp className='h-full text-blue-500' />
                        <span className='text-base ml-2 my-auto text-slate-900 flex-grow text-center'>ย้อนกลับ</span>
                    </div>
                </>
            );
        }
        if (location.pathname.includes('/otms/admin/invoice/')) {
            return (
                <>
                    <div className='px-4 py-0 md:py-4 lg:py-4 flex justify-center border-blue-500 bg-slate-50 lg:hover:border-l-4 md:hover:border-l-4 rounded-lg my-1 md:my-0 lg:my-0 ease-in-out duration-300 hover:text-[25px]' onClick={() => { navigate(-1) }}>
                        <IoChevronBackSharp className='h-full text-blue-500' />
                        <span className='text-base ml-2 my-auto text-slate-900 flex-grow text-center'>ย้อนกลับ</span>
                    </div>
                </>
            );
        }
        if (location.pathname.includes('/otms/admin/class/')) {
            return (
                <>
                    <div className='px-4 py-0 md:py-4 lg:py-4 flex justify-center border-blue-500 bg-slate-50 lg:hover:border-l-4 md:hover:border-l-4 rounded-lg my-1 md:my-0 lg:my-0 ease-in-out duration-300 hover:text-[25px]' onClick={() => { navigate("/otms/admin") }}>
                        <IoChevronBackSharp className='h-full text-blue-500' />
                        <span className='text-base ml-2 my-auto text-slate-900 flex-grow text-center'>ย้อนกลับ</span>
                    </div>
                </>
            );
        }

        return (
            <>
                {/* <div className='px-2 flex-grow bg-slate-50 rounded-lg my-1 ease-in-out duration-300 text-am'>
                    {
                        pims?.studentAuthor?.student?.firstName + " " + pims?.studentAuthor?.student?.lastName
                    }
                </div> */}
                <div className={`py-0 md:py-4 lg:py-4 px-4 flex justify-left border-blue-500 ${location.pathname == '/otms' ? 'text-[25px] border-t-4 lg:border-t-0 md:border-t-0 lg:border-l-4 md:border-l-4' : 'text-auto'} bg-slate-50 lg:hover:border-l-4 md:hover:border-l-4 rounded-lg my-1 md:my-0 lg:my-0 ease-in-out duration-300 hover:text-[25px]`} onClick={() => { navigate("/otms") }}>
                    <FaClipboardList className='h-full text-blue-500 relative left-0' />
                    <span className='text-base ml-2 my-auto text-slate-900 flex-grow text-center'>บิลที่ทำรายการ</span>
                </div>
                <div className={`py-0 md:py-4 lg:py-4 px-4 flex justify-left border-purple-500 ${location.pathname == '/otms/upload' ? 'text-[25px] border-t-4 lg:border-t-0 md:border-t-0 lg:border-l-4 md:border-l-4' : 'text-auto'} bg-slate-50 lg:hover:border-l-4 md:hover:border-l-4 rounded-lg my-1 md:my-0 lg:my-0 ease-in-out duration-300 hover:text-[25px]`} onClick={() => { navigate("/otms/upload") }}>
                    <FaFileUpload className='h-full text-purple-500' />
                    <span className='text-base ml-2 my-auto text-slate-900 flex-grow text-center'>อัพโหลดรายการ</span>
                </div>
                {
                    (pims?.role?.name === 'admin' || pims?.role?.name === 'employee') && (
                        <div className={`py-0 md:py-4 lg:py-4 px-4 flex justify-left border-yellow-500 ${location.pathname.includes('/otms/admin') ? 'text-[25px] lg:border-t-0 md:border-t-0 border-t-4 lg:border-l-4 md:border-l-4' : 'text-auto'} bg-slate-50 lg:hover:border-l-4 md:hover:border-l-4 rounded-lg my-1 md:my-0 lg:my-0 ease-in-out duration-300 hover:text-[25px]`} onClick={() => { navigate("/otms/admin") }}>
                            <FaAngular className='h-full text-yellow-500' />
                            <span className='text-base ml-2 my-auto text-slate-900 flex-grow text-center'>จัดการใบเสร็จ</span>
                        </div>
                    )
                }
            </>
        )
    }
    return (
        <div className='flex-grow flex flex-col-reverse md:flex-row lg:flex-row px-0 md:px-5 lg:px-5 overflow-hidden gap-2'>
            <div className='flex relative max-h-[10%] h-[10%] min-h-[10%] gap-4 md:hidden lg:hidden mx-2 mb-2 rounded-lg border-[1px] border-white overflow-hidden'>
                <div className='absolute bg-slate-50 opacity-50 w-full h-full z-0 '></div>
                <div className='z-10 flex flex-grow justify-center gap-2 overflow-hidden'>
                    {
                        navigation()
                    }
                    <div className='px-4 bg-slate-50 rounded-lg my-1 ease-in-out duration-300 hover:text-[25px] flex' onClick={handleLogout}>
                        <RiLogoutBoxRFill className='h-full text-red-500' />
                        <span className='text-base ml-2 my-auto text-slate-900 flex-grow text-center'>ลงชื่อออก</span>
                    </div>
                </div>
            </div>
            <div className='hidden md:flex lg:flex relative gap-4 mx-2 mb-2 rounded-lg border-[1px] border-white overflow-hidden'>
                <div className='absolute bg-slate-50 opacity-50 w-full h-full z-0 '></div>
                <div className='z-10 flex flex-grow gap-1 overflow-hidden flex-col w-[200px]'>
                    {
                        navigation()
                    }
                    <div className='py-4 px-4 bg-slate-50 rounded-lg flex justify-left ease-in-out duration-300 text-[25px] hover:text-[35px] border-red-500 lg:hover:border-l-4 md:hover:border-l-4' onClick={handleLogout}>
                        <RiLogoutBoxRFill className='h-full text-red-500' />
                        <span className='text-base ml-2 my-auto text-slate-900 flex-grow text-center'>ลงชื่อออก</span>
                    </div>
                </div>
            </div>
            {/* <div className='hidden flex-col bg-slate-500 gap-4 md:flex lg:flex'>
                <span className='my-auto'>
                    DashboardOTMS
                </span>
                <div className='bg-pink-300 flex-grow flex flex-col justify-center gap-4'>
                    <button className='bg-white p-2' onClick={() => { navigate("/otms") }}>List</button>
                    <button className='bg-white p-2' onClick={() => { navigate("/otms/upload") }}>Upload</button>
                    {
                        pims?.role?.name === 'admin' && (
                            <button className='bg-white p-2' onClick={() => { navigate("/otms/admin") }}>Admin</button>
                        )
                    }
                </div>
                <span className='ml-auto'>
                    <span className='mr-5'>
                        {
                            pims?.studentAuthor?.student?.firstName + " " + pims?.studentAuthor?.student?.lastName
                        }
                    </span>
                    <button onClick={handleLogout} className='bg-white p-2 border-2'>Logout</button>
                </span>
            </div> */}
            <div className='flex-grow mx-2 rounded-lg overflow-auto mb-2 border-[1px] border-white bg-gray-100 bg-opacity-60'>
                <Outlet />
            </div>
        </div>
    )
}

export default DashboardOTMS